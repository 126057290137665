import React, { useState } from "react";
import { Openning } from "../../organims";
import { Button, Col, Form, Input, Row } from "antd";
import "./styles.css";
import {
  cancelReservation,
  clearSelectedOptions,
  getCalendar,
  getDatas,
  getReservations,
  setSelectedCategory,
  setSelectedService,
} from "../../../redux/actions/categoryActions";
import { filteredDate } from "../../../utils/dateList";
import { useDispatch, useSelector } from "react-redux";
import LoadingScreen from "../loading-screen/LoadingScreen";
import { toast } from "react-toastify";

function RendezVousTemplate() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { dataLoading } = useSelector((state) => state.categoryReducer);
  const [reservations, setReservations] = useState([]);
  const onGetReservations = (value) => {
    getReservations(value).then((res) => setReservations(res.data));
  };
  const onCancel = (id) => {
    let fields = form.getFieldsValue();
    cancelReservation(id)
      .then((res) => {
        toast(res.data.message, { type: "success" });
        onGetReservations(fields);
        getDatas(dispatch);
        getCalendar(dispatch);
        setSelectedCategory(null, dispatch);
        setSelectedService(null, dispatch);
        clearSelectedOptions(dispatch);
      })
      .catch((err) => {
        toast(err.response.data.message, { type: "error" });
      });
  };
  return dataLoading ? (
    <LoadingScreen />
  ) : (
    <div>
      <div className="main_container">
        <div className="prestation_title_container">
          <div className="prestation_title">
            <h2>
              <span>Mes rendez-vous</span>
            </h2>
          </div>
        </div>
      </div>
      <div className="main_container">
        <div className="rendez_vous_form_container">
          <Form layout="vertical" onFinish={onGetReservations} form={form}>
            <Form.Item
              label={
                <label className="rendez_vous_form_label">
                  Votre adresse E-mail ou numéro de téléphone
                  <br />
                  <span style={{ fontSize: 14 }}>
                    (le numéro doit être au format 0612345678 sans espace)
                  </span>
                </label>
              }
              name="value"
              rules={[
                {
                  required: true,
                  message:
                    "Saisissez votre adresse E-mail ou numéro de téléphone!",
                },
              ]}
            >
              <Input
                placeholder="Votre adresse E-mail ou numéro de téléphone"
                className="rendez_vous_form_input"
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="rendez-vous-form-button"
              >
                Vos rendez-vous
              </Button>
            </Form.Item>
          </Form>
        </div>
        {reservations.length > 0 && (
          <div className="main_container">
            <div className="rendez_vous_table_container">
              <div className="appointmensTable">
                <div className="appointmensTableHeader">
                  <Row>
                    <Col lg={4} xs={24}>
                      <div className="rightBorderWhite">
                        <div className="p10">Prénom</div>
                      </div>
                    </Col>
                    <Col lg={7} xs={24}>
                      <div className="rightBorderWhite">
                        <div className="p10">Prestation</div>
                      </div>
                    </Col>
                    <Col lg={3} xs={24}>
                      <div className="rightBorderWhite">
                        <div className="p10">Date</div>
                      </div>
                    </Col>
                    <Col lg={2} xs={24}>
                      <div className="rightBorderWhite">
                        <div className="p10">H. début</div>
                      </div>
                    </Col>
                    <Col lg={2} xs={24}>
                      <div className="rightBorderWhite">
                        <div className="p10">H. fin</div>
                      </div>
                    </Col>
                    <Col lg={4} xs={24}>
                      <div className="rightBorderWhite">
                        <div className="p10">Prestatrice</div>
                      </div>
                    </Col>
                    <Col lg={2} xs={24}>
                      <div>
                        <div className="p10">Action</div>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="appointmensTableBody">
                  {reservations.map((reservation, i) => (
                    <div
                      key={reservation._id}
                      className={
                        i < reservations.length - 1 ? "bottomBorderWhite" : ""
                      }
                    >
                      <Row>
                        <Col lg={4} xs={24}>
                          <div className="rightBorderWhite">
                            <div className="p10">
                              <span className="appointmensTableLabel">
                                Prénom :
                              </span>
                              {reservation.firstname}
                            </div>
                          </div>
                        </Col>
                        <Col lg={7} xs={24}>
                          <div className="rightBorderWhite">
                            <div className="p10">
                              <span className="appointmensTableLabel">
                                Service :
                              </span>
                              {reservation.service.name} (
                              {reservation.service.price}
                              €)
                            </div>
                          </div>
                        </Col>
                        <Col lg={3} xs={24}>
                          <div className="rightBorderWhite">
                            <div className="p10">
                              <span className="appointmensTableLabel">
                                Date :
                              </span>
                              {filteredDate(reservation.date)}
                            </div>
                          </div>
                        </Col>
                        <Col lg={2} xs={24}>
                          <div className="rightBorderWhite">
                            <div className="p10">
                              <span className="appointmensTableLabel">
                                H. début :
                              </span>
                              {reservation.time}
                            </div>
                          </div>
                        </Col>
                        <Col lg={2} xs={24}>
                          <div className="rightBorderWhite">
                            <div className="p10">
                              <span className="appointmensTableLabel">
                                H. fin :
                              </span>
                              {reservation.endTime}
                            </div>
                          </div>
                        </Col>
                        <Col lg={4} xs={24}>
                          <div className="rightBorderWhite">
                            <div className="p10">
                              <span className="appointmensTableLabel">
                                Prestatrice :
                              </span>
                              {reservation.prestatrice.name}
                            </div>
                          </div>
                        </Col>
                        <Col lg={2} xs={24}>
                          <div>
                            <div className="p10">
                              <Button onClick={() => onCancel(reservation._id)}>
                                Annuler
                              </Button>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Openning />
    </div>
  );
}

export default RendezVousTemplate;
