import React, { useEffect, useState } from "react";
import "./styles.css";
import { useDispatch, useSelector } from "react-redux";
import { MyButton, MyButtonPhone } from "../../atoms";
import { useNavigate } from "react-router-dom";
import { changeActivePrestatrice } from "../../../redux/actions/categoryActions";
import { toast } from "react-toastify";

function PrestatricesCalendar(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    appointmentsIndex,
    activeDate,
    appointments,
    categories,
    services,
    selectedCategory,
    selectedService,
    selectedCoiffureOptions,
  } = useSelector((state) => state.categoryReducer);
  const [activeService, setActiveService] = useState("");
  const [isReservable, setIsReservable] = useState(true);
  const [activePrestatrice, setActivePrestatrice] = useState([]);
  useEffect(() => {
    const categoryIndex = categories.findIndex(
      (item) => item._id === selectedCategory
    );
    const serviceIndex = categories[categoryIndex].services.findIndex(
      (item) => item._id === selectedService
    );
    setActiveService({
      _id: categories[categoryIndex].services[serviceIndex]._id,
      name: categories[categoryIndex].services[serviceIndex].name,
      duration: categories[categoryIndex].services[serviceIndex].duration,
      price: categories[categoryIndex].services[serviceIndex].price,
    });
  }, [selectedService, categories, selectedCategory]);

  useEffect(() => {
    setActivePrestatrice([]);
    setIsReservable(
      services[services.findIndex((service) => service._id === selectedService)]
        .isReservable
    );
    let activeArray = null;
    if (appointmentsIndex >= 0) {
      activeArray =
        appointments[appointmentsIndex]?.calendar[
          appointments[appointmentsIndex]?.calendar.findIndex((item) => {
            return item.date === activeDate;
          })
        ];
    }
    let prestatrices = []
    if(activeArray) {
       prestatrices = activeArray.prestatrices?.filter((item) =>
        item.services.includes(selectedService)
      );
    } 
    let filteredPrestatrices = [];
    if (prestatrices) {
      for (let i = 0; i < prestatrices.length; i++) {
        const presta = prestatrices[i];
        for (let j = 0; j < presta.appointmentSchedule.length; j++) {
          const app = presta.appointmentSchedule[j];
          const categoryIndex = categories.findIndex(
            (item) => item._id === selectedCategory
          );
          const serviceIndex = categories[categoryIndex].services.findIndex(
            (item) => item._id === selectedService
          );
          let duration =
            categories[categoryIndex].services[serviceIndex].duration;
          let appointmentsList = app[`appointments_${duration}`];
          if (appointmentsList.length > 0) {
            filteredPrestatrices.push({
              ...presta,
              appointmentSchedule: appointmentsList,
            });
          }
        }
      }
    }
    setActivePrestatrice(filteredPrestatrices);
  }, [
    activeDate,
    appointments,
    appointmentsIndex,
    categories,
    selectedCategory,
    selectedService,
    services,
  ]);

  const onSelectPrestatrice = (id, time) => {
    const category =
      categories[categories.findIndex((item) => item._id === selectedCategory)];
    if (category.short === "Coiffure" && !selectedCoiffureOptions) {
      return toast("Veuillez selectionner une option", { type: "error" });
    }
    const categoryIndex = categories.findIndex(
      (item) => item._id === selectedCategory
    );
    const activeCategory = {
      _id: categories[categoryIndex]._id,
      name: categories[categoryIndex].name,
      short: categories[categoryIndex].short,
    };
    changeActivePrestatrice(id, time, activeCategory, activeService, dispatch);
    navigate("/appointment");
  };
  return (
    <div className="main_container">
      <div className="prestatrices-calendar-container">
        <div className="prestatrices-calendar-title">
          <h2>
            {isReservable
              ? "Rendez-vous disponible"
              : "Veuillez appeler pour prendre rendez-vous"}
          </h2>
          {isReservable && (
            <div className="prestatrices-calendar-menu">
              <div className="prestatrices-calendar-item">Horaires</div>
              <div className="prestatrices-calendar-item prestatrices-calendar-item-middle">
                Prestatrices
              </div>
              <div className="prestatrices-calendar-item">Réservation</div>
            </div>
          )}
        </div>
        <div>
          {isReservable ? (
            activePrestatrice.map((prestatrice, i) =>
              prestatrice.appointmentSchedule.map((appointment, j) => (
                <div
                  className={
                    i === 0 && j === 0
                      ? "prestatrices-calendar-menu-flex"
                      : "prestatrices-calendar-menu-white"
                  }
                  key={i + "" + j}
                >
                  <div className="prestatrices-calendar-item">
                    {appointment}
                  </div>
                  <div className="prestatrices-calendar-item prestatrices-calendar-item-middle-white">
                    <div
                      style={{
                        backgroundColor: prestatrice.color
                          ? prestatrice.color
                          : "transparent",
                        padding: "10px 20px",
                        borderRadius: 50,
                      }}
                    >
                      {prestatrice.name}
                    </div>
                  </div>
                  <div className="prestatrices-calendar-item">
                    <MyButton
                      title="Réserver"
                      onClick={() =>
                        onSelectPrestatrice(prestatrice._id, appointment)
                      }
                    />
                  </div>
                </div>
              ))
            )
          ) : (
            <div className="p20">
              {activePrestatrice.map((presta, i) => (
                <div
                  className={`${
                    i < activePrestatrice.length - 1 && "mb20"
                  } w100`}
                  key={i}
                >
                  <MyButtonPhone
                    title={`Appeler ${presta.name} - ${presta.phone}`}
                    href={`tel:${presta.phone}`}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default PrestatricesCalendar;
